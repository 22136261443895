import styled, { createGlobalStyle } from 'styled-components'
import Img from 'gatsby-image'

export const ErrorBrowserWhiteBackground = createGlobalStyle`
  body {
    background-color: #fff;
  }
`

export const ErrorBrowserWrapper = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  min-height: 80vh;
`

export const ErrorBrowserLogo = styled(Img)`
  margin-bottom: 27px;
  width: 300px;
`

export const ErrorBrowserMessage = styled.p`
  font-size: 20px;
  color: #4e566d;
  text-align: center;
  line-height: 1.4;
`

import styled from 'styled-components'

import media from '../../styles/media-query'

export const Spacing = styled.hr`
  border: 0;
  margin: 20px 0;
`

export const RegulationTitle = styled.h2`
  text-align: center;
  ${media.lessThan('680px')`
    font-size: 16px !important;
  `}
`

export const RegulationBody = styled.div`
  .gatsby-resp-image-wrapper {
    margin: 40px auto;
  }
  li {
    margin-bottom: 16px;
  }
`

import styled from 'styled-components'
import media from '../../styles/media-query'

export const ModalLayoutWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  min-height: 100vh;
  left: 0;
  right: 0;
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(44, 47, 58, 0.3);
    opacity: 1;
  }
`
export const ModalLayoutContainer = styled.section`
  width: 100%;
  max-width: 930px;
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
  margin: auto;
  position: relative;
  ${(props) =>
    props.hasButton &&
    `
    padding-bottom: 30px;
  `}
  ${media.lessThan('sm')`
    padding: 40px 20px;
    border-radius: 0;
    min-height: 100vh;
  `}
`

export const ModalLayoutHeader = styled.header`
  display: flex;
  padding-bottom: 40px;
`

export const ModalLayoutHeaderTitle = styled.h1`
  margin-top: 0;
  font-weight: bold;
  font-size: 32px;
  color: #4694f7;
  display: flex;
  align-items: center;
  ${media.lessThan('sm')`
    color: #4C5161;
  `}
  img {
    width: 26px;
    margin-right: 20px;
    &:first-child {
      ${media.lessThan('sm')`
        display: none;
      `}
    }
    &:last-child {
      display: none;
      ${media.lessThan('sm')`
        display: block;
      `}
    }
  }
`

export const ModalLayoutHeaderCloseLink = styled.a`
  margin-left: auto;
  cursor: pointer;
  img {
    &:first-child {
      ${media.lessThan('sm')`
        display: none;
      `}
    }
    &:last-child {
      display: none;
      ${media.lessThan('sm')`
        display: block;
      `}
    }
  }
`

export const ModalLayoutContent = styled.div`
  max-height: 50vh;
  ${media.lessThan('sm')`
    max-height: calc(100vh - 72px - 80px${(props) =>
      props.hasButton && ' - 84px'});
  `}
  min-height: 100px;
  overflow: auto;
  padding: 10px;
  color: #4c445a;
  line-height: 1.3;
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  h3 {
    font-weight: 600;
    margin: 20px 0 5px;
  }

  ::-webkit-scrollbar {
    width: 20px;
    ${media.lessThan('md')`
      width: 10px;
    `}
  }

  ::-webkit-scrollbar-track {
    background: rgba(139, 152, 186, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #4694f7;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #0984fe;
  }
`

export const DownloadRegulationButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`

export const DownloadRegulationButton = styled.a`
  background: #2793ff;
  border-radius: 8px;
  color: #fff;
  padding: 12px 40px;
  text-decoration: none;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: auto;
  > img {
    height: 20px;
    margin-right: 10px;
  }
  > span {
    display: block;
    width: 100%;
    white-space: nowrap;
  }
  &:hover,
  &:focus {
    background-color: #0984fe;
  }
`

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

const ErrorBrowser = () => {
  const { pegLogo } = useStaticQuery(
    graphql`
      query {
        pegLogo: file(relativePath: { eq: "peg-logo.png" }) {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <S.ErrorBrowserWrapper>
      <S.ErrorBrowserWhiteBackground />
      <S.ErrorBrowserLogo fixed={pegLogo.childImageSharp.fixed} />
      <S.ErrorBrowserMessage>
        O PEG Bits não dá suporte ao Internet Explorer.
        <br />
        Por favor acesse pelo Google Chrome.
      </S.ErrorBrowserMessage>
    </S.ErrorBrowserWrapper>
  )
}

export default ErrorBrowser

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ModalLayout from '../ModalLayout'
import * as S from './styled'

const ModalRegulation = ({ toggleShowModal }) => {
  const { regulationPage } = useStaticQuery(
    graphql`
      query {
        regulationPage: markdownRemark(
          frontmatter: { name: { eq: "regulation" } }
        ) {
          html
          frontmatter {
            date
            title
            file {
              publicURL
            }
          }
        }
      }
    `
  )

  const regulationFileUrl = regulationPage.frontmatter.file.publicURL

  return (
    <ModalLayout
      title="Regulamento"
      toggleShowModal={toggleShowModal}
      downloadRegulationButton={regulationFileUrl}
    >
      <S.RegulationTitle
        dangerouslySetInnerHTML={{ __html: regulationPage.title }}
      />
      <S.RegulationBody
        dangerouslySetInnerHTML={{ __html: regulationPage.html }}
      />
    </ModalLayout>
  )
}

export default ModalRegulation

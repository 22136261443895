import styled from 'styled-components'

const formInputPaddingHorizontal = '14px'
const formInputPaddingVertical = '10px'
const formInputBorderSize = '1px'

const formInputLabelPaddingHorizontal = '4px'

export const FormInputWrapper = styled.div`
  position: relative;
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.8;
  `}
`

export const FormInputLabel = styled.label`
  font-size: 14px;
  color: rgba(76, 68, 90, 0.5);
  position: absolute;
  display: block;
  border-radius: 4px;
  left: calc(${formInputPaddingHorizontal} / 2);
  padding: ${formInputLabelPaddingHorizontal}
    calc(${formInputPaddingHorizontal} / 2);
  transform: translate3d(
    0,
    calc(
      ${formInputPaddingVertical} - ${formInputLabelPaddingHorizontal} +
        ${formInputBorderSize}
    ),
    0
  );
  background: #fff;
  transition: all 0.2s;
  user-select: none;
  cursor: text;

  &[disabled] {
    background-color: #fff;
    cursor: not-allowed;
  }
  ${(props) =>
    props.hasValue &&
    `
    color: #1a284d;
    font-size: 10px;
    transform: translate3d(0, calc(-50% - 1px), 0);
  `}
  ${(props) =>
    props.isFocused &&
    `
    color: #2793FF;
    font-size: 10px;
    transform: translate3d(0, calc(-50% - 1px), 0);
  `}
  ${(props) =>
    props.hasError &&
    `
    color: #FE5959;
  `}
`

export const FormInputElement = styled.input`
  border: ${formInputBorderSize} solid #8b98ba;
  border-radius: 4px;
  padding: ${formInputPaddingVertical} ${formInputPaddingHorizontal};
  font-size: 14px;
  width: 100%;
  outline: none;
  line-height: 1;
  color: #4c445a;
  outline: none;
  transition: 0.2s;

  &[data-has-value='true'] {
    border-color: #1a284d;
  }
  &:hover {
    border-color: #1a284d;
  }
  &:focus {
    border-color: #2793ff;
  }
  &[data-has-error='true'] {
    border-color: #fe5959;
  }
  &[disabled] {
    background-color: #fff;
    cursor: not-allowed;
  }
  ${(props) =>
    props.isSelect &&
    `
    cursor: pointer;
    user-select: none;
  `}
`

export const FormInputErrorMessage = styled.div`
  color: #fe5959;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  padding: 3px 0;
  > img {
    height: 13px;
    width: auto;
    display: inline-block;
    margin: -6px 6px -6px 0;
    user-select: none;
  }
`

export const FormInputElementSelectArrow = styled.img`
  position: absolute;
  width: 12px;
  right: 18px;
  top: 19.4px;
  transform: translate3d(0, -50%, 0);
  transition: transform 0.2s;
  ${(props) =>
    props.invert &&
    `
    transform: translate3d(0, -50%, 0) rotate(180deg);
  `}
`

export const FormInputElementOptionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  border-top: 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  margin-top: 3px;
  top: 38.8px;
  overflow: hidden;
`

export const FormInputElementOptions = styled.ul``

export const FormInputElementOption = styled.li``

export const FormInputElementOptionButton = styled.button`
  background: transparent;
  border: 0;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  ${(props) =>
    props.isSelected &&
    `
    background-color: #F5F6F9;
  `}
  &:hover,
  &:focus {
    color: #fff;
    background-color: #2793ff;
  }
`

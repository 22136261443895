import styled from 'styled-components'
import Img from 'gatsby-image'

import media from '../../styles/media-query'
import FormInput from '../FormInput'

export const MainWrapper = styled.div`
  display: flex;
  ${media.lessThan('sm')`
    flex-direction: column-reverse;
  `}
  * {
    outline: none;
  }
`

export const MainFormContainer = styled.div`
  width: 500px;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;

  ${media.lessThan('lg')`
    width: 469px;
  `}
  ${media.lessThan('sm')`
    width: 100%;
  `}
`

export const MainLogoWrapper = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
  padding: 40px 0 0;
  position: relative;

  &:after {
    content: '';
    display: block;
    background: #fff;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    bottom: -32px;
    height: 120px;
    width: 100%;
    position: absolute;
    transform: SkewY(-9deg);
    transform-origin: bottom right;
  }

  ${media.lessThan('sm')`
    display: none;
  `}
`

export const MainLogo = styled(Img)`
  position: relative;
  z-index: 1;
  max-width: 330px;
  width: 100%;
`

export const MainFormWrapper = styled.div`
  background-color: #fff;
  color: #4c445a;
  padding: 10px 50px 50px;
  flex: 1;
  position: relative;
  ${media.lessThan('lg')`
    padding-left: 30px;
    padding-right: 30px;
  `}

  ${media.lessThan('sm')`
    margin-top: 120px;
  `}

  &:before {
    content: '';
    display: block;
    background: #fff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    top: -46px;
    left: 0;
    height: 130px;
    width: 100%;
    position: absolute;
    transform: SkewY(-9deg);
    transform-origin: bottom left;

    ${media.lessThan('sm')`
      height: 150px
    `}
  }
`

export const MainFormWrapperDecorationSmallWrapper = styled.div`
  display: none;
  ${media.lessThan('610px')`
    display: block;
    margin-bottom: -160px;
    margin-right: -20px;
  `}
`

export const MainFormWrapperDecorationSmall = styled(Img)``

export const MainFormDescription = styled.div`
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 20px;
  position: relative;
`

export const MainFormDescriptionTitle = styled.h1`
  font-size: 29px;
  font-weight: bold;
  margin-bottom: 16px;
`

export const MainFormDescriptionText = styled.p`
  font-size: 20px;
  strong {
    font-weight: 600;
  }
`

export const MainForm = styled.form``

export const MainFormStepsController = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
  &:before {
    content: '';
    display: block;
    height: 5px;
    left: 2px;
    right: 2px;
    top: 50%;
    position: absolute;
    transform: translate3d(0, -50%, 0);
    background: #daeafd;
  }
  &:after {
    content: '';
    display: block;
    height: 5px;
    left: 2px;
    width: calc(
      ${(props) => {
          if (props.currentStep === 1) return '2px'
          if (props.currentStep === 2) return '50%'
          return '100%'
        }} - 4px
    );
    top: 50%;
    position: absolute;
    transform: translate3d(0, -50%, 0);
    background: #2793ff;
    transition: width 0.2s;
  }
`

export const MainFormStepsControllerOption = styled.button`
  border: 0;
  background: transparent;
  position: relative;
  z-index: 1;
  padding: 0;
  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    border-radius: 300px;
    background: #daeafd;
    transition: background 0.2s;
    ${(props) => props.isActive && 'background: #2793FF;'}
  }
`

export const MainFormStepsControllerOptionDescription = styled.span`
  position: absolute;
  white-space: nowrap;
  left: 50%;
  font-size: 12px;
  color: #8b98ba;
  font-weight: bold;
  transform: translate3d(-50%, 6px, 0);
  transition: color 0.2s;
  ${(props) => props.isActive && 'color: #2793FF;'}
`

export const MainFormStep = styled.div`
  display: none;
  ${(props) =>
    props.isCurrentStep &&
    `
    display: block;
    min-height: 432px;
  `}
`

export const MainFormStepTitle = styled.p`
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 20px;
`

export const MainFormInput = styled(FormInput)`
  margin-bottom: 16px;
  ${(props) =>
    props.error &&
    `
    margin-bottom: 8px;
  `}
`

export const MainFormAgreement = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
`

export const MainFormAgreementCheckbox = styled.input`
  cursor: pointer;
  outline: none;
  margin: 0;
  line-height: 1;
  outline: none;

  ${(props) =>
    props.hasError &&
    `
    filter:
      drop-shadow(1px 0 0 #FE5959)
      drop-shadow(-1px 0 0 #FE5959)
      drop-shadow(0 1px 0 #FE5959)
      drop-shadow(0 -1px 0 #FE5959);
  `}

  &:focus {
    filter: drop-shadow(1px 0 0 #2793ff) drop-shadow(-1px 0 0 #2793ff)
      drop-shadow(0 1px 0 #2793ff) drop-shadow(0 -1px 0 #2793ff);
  }
`

export const MainFormAgreementLabel = styled.span`
  font-size: 14px;
  padding-left: 6px;
  button {
    line-height: 1;
    background: transparent;
    color: currentColor;
    text-decoration: underline;
    border: 0;
    padding: 0;
    margin-left: 1px;
    cursor: pointer;
    transition: 0.2s;
    &:hover,
    &:focus {
      color: #2793ff;
    }
  }
`

export const MainFormAgreementError = styled.div`
  width: 100%;
  color: #fe5959;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  padding: 3px 0;
  > img {
    height: 13px;
    width: auto;
    display: inline-block;
    margin-right: 6px;
    user-select: none;
  }
`

export const MainFormSubmitButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`

export const MainFormSubmitButton = styled.button`
  font-size: 16px;
  color: #fff;
  padding: 12px 32px;
  background: #2793ff;
  border-radius: 8px;
  border: 1px solid #2793ff;
  user-select: none;
  transition: 0.2s;
  cursor: pointer;
  &:last-child {
    margin-left: auto;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:focus,
  &:hover {
    background-color: #0984fe;
    border-color: #0984fe;
  }

  @keyframes spincenter {
    from {
      transform: translateY(-50%) translateX(-50%) rotate(0deg);
    }

    to {
      transform: translateY(-50%) translateX(-50%) rotate(360deg);
    }
  }

  &:before {
    color: #fff;
  }
  ${(props) =>
    props.isLoading &&
    `
    position: relative;
    cursor: not-allowed;
    color: transparent !important;

    &:before {
      top: 50%;
      left: 50%;
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 50%;
      position: absolute;
      border: 2px solid currentColor;
      border-left-color: transparent;
      animation: spincenter 1s infinite linear;
    }
  `}

  ${(props) =>
    props.secondary &&
    `
    background: #fff;
    color: #2793ff;
    &:focus, &:hover {
      background-color: #f6f7fb;
    }
    &:before {
      color: #2793ff;
    }
  `}
`

export const MainDescriptionContainer = styled.section`
  margin-left: 100px;
  padding-top: 96px;
  flex: 1;

  ${media.lessThan('lg')`
    margin-left: 59px;
  `}
  ${media.lessThan('sm')`
    padding-top: 0;
    margin-left: 0;
  `}
`

export const MainDescriptionHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;

  ${media.lessThan('md')`
    flex-direction: column-reverse;
    align-items: flex-start;
  `}

  ${media.lessThan('sm')`
    position: absolute;
    width: 100%;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
  `}
`

export const MainDescriptionHeading = styled.h2`
  font-size: 32px;
  font-weight: bolder;
  ${media.lessThan('lg')`
    font-size: 30px;
  `}
  ${media.lessThan('md')`
    margin-top: 50px;
  `}
  ${media.lessThan('sm')`
    display: none;
  `}
`

export const MainDescriptionHeadingSmall = styled(MainDescriptionHeading)`
  display: none;
  ${media.lessThan('sm')`
    display: block;
    margin-top: 310px;
  `}
  ${media.lessThan('564px')`
    margin-top: 280px;
  `}
  ${media.lessThan('520px')`
    margin-top: 260px;
  `}
`

export const MainDescriptionLinksWrapper = styled.div`
  margin-left: auto;

  ${media.lessThan('md')`
    margin-left: 0;
  `}

  ${media.lessThan('sm')`
    margin-bottom: 100px;
    padding: 50px 30px 0;
    background-color: #fff;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    &:after {
      content: "";
      display: block;
      background: #fff;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      bottom: -32px;
      left: 0;
      height: 120px;
      width: 100%;
      position: absolute;
      transform: SkewY(-9deg);
      transform-origin: bottom right;
    }
  `}
`

export const MainDescriptionLinksLogoWrapper = styled.div`
  display: none;
  ${media.lessThan('sm')`
    display: flex;
    align-items: center;
    width: 100%;
  `}
`

export const MainDescriptionLinksLogo = styled(MainLogo)`
  width: 60%;
  min-width: 200px;
  max-width: 330px;
`

export const MainDescriptionLinksToggler = styled.button`
  position: relative;
  z-index: 1;
  border: 0;
  background: transparent;
  margin-left: auto;
  padding: 0 4px;
  width: 44px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    height: 28px;
  }
`

export const MainDescriptionLinks = styled.nav`
  border-radius: 16px;
  background-color: #57a1ff;
  padding: 15px 25px;
  display: flex;

  ${media.lessThan('sm')`
    margin: 0 auto;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    z-index: 1;
    flex-direction: column;

    margin-top: 0;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all .2s;

    ${(props) =>
      props.show &&
      `
      margin-top: 40px;
      opacity: 1;
      height: auto;
    `}
  `}
`

export const MainDescriptionLink = styled.a`
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  color: #fff;
  font-weight: bold;
  font-size: 16px;

  ${media.greaterThan('sm')`
    &:not(:first-child) {
      margin-left: 35px;
      ${media.lessThan('lg')`
        margin-left: 25px;
      `}
    }
  `}

  ${media.lessThan('sm')`
    color: #4C5161;
    font-size: 20px;
    font-weight: bolder;
    margin: 10px 0;
    padding-top: 10px;
    padding-bottom: 10px;

    &:first-of-type {
      > img {
        margin-right: 10px;
        margin-left: 2px;
      }
    }
  `}
`

export const MainDescriptionLinkIcon = styled.img`
  height: 20px;
  width: auto;
  margin-right: 8px;
  user-select: none;
  ${media.lessThan('sm')`
    display: none;
  `}
`

export const MainDescriptionLinkIconSmall = styled(MainDescriptionLinkIcon)`
  display: none;
  ${media.lessThan('sm')`
    display: block;
    height: 24px;
  `}
`

export const MainDescriptionSteps = styled.ol`
  margin-top: 80px;
  display: grid;
  width: 100%;
  row-gap: 60px;
  column-gap: 30px;
  grid-template:
    'step-1 step-2'
    'step-3 step-4'
    / 1fr 1fr;

  ${media.lessThan('md')`
    row-gap: 30px;
    margin-top: 40px;
    grid-template:
      "step-1"
      "step-2"
      "step-3"
      "step-4";
  `}
  ${media.lessThan('sm')`
    margin-bottom: 40px;
  `}
`

export const MainDescriptionStep = styled('li', { stepNumber: Number })`
  grid-area: step-${(props) => props.stepNumber};
  display: flex;
  align-items: flex-start;
`

export const MainDescriptionStepNumber = styled.div`
  font-size: 64px;
  font-weight: bolder;
  color: #2793ff;
  background: ${(props) => {
    if (props.stepNumber === 4) return 'rgba(255, 255, 255, 0.4)'
    if (props.stepNumber === 3) return 'rgba(255, 255, 255, 0.6)'
    if (props.stepNumber === 2) return 'rgba(255, 255, 255, 0.8)'
    return '#FFFFFF'
  }};
  border-radius: 16px;
  display: flex;
  width: 92px;
  height: 92px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 20px;
  user-select: none;
  &:before {
    content: '${(props) => props.stepNumber}';
  }

  ${media.lessThan('lg')`
    font-size: 56px;
    width: 80px;
    height: 80px;
  `}
  ${media.lessThan('xs')`
    font-size: 46px;
    width: 66px;
    height: 66px;
  `}
`

export const MainDescriptionStepText = styled.div``

export const MainDescriptionStepTitle = styled.h3`
  font-weight: bold;
  font-size: 34px;
  color: #b5d4fc;
  margin-bottom: 10px;
`

export const MainDescriptionStepDescription = styled.p`
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  strong {
    font-weight: bolder;
  }
`

export const MainMobileMenuFilter = styled.div`
  display: none;

  ${media.lessThan('sm')`
    backdrop-filter: blur(10px);
    background-color: rgba(44, 47, 58, 0.3);
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    transform: translate3d(-50%, 0, 0);
    transition: all .2s;
    display: block;
    ${(props) =>
      props.show &&
      `
      bottom: 0;
    `}
  `}
`

export const MainFormCompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`

export const MainFormCompleteTitle = styled.h3`
  font-weight: bold;
  font-size: 29px;
  text-align: center;
  color: #4c445a;
`

export const MainFormCompleteText = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 130%;
  color: #4c445a;
  margin: 48px 0;
`

export const MainFormContainerBackgroundDecorationWrapper = styled.div`
  width: 720px;
  position: absolute;
  bottom: 0;
  right: 17px;
  user-select: none;
  ${media.lessThan('640px')`
    display: none;
  `}
`

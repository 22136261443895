import React from 'react'

import Layout from '../components/Layout'
import ModalRegulation from '../components/ModalRegulation'
import ModalClients from '../components/ModalClients'
import SEO from '../components/seo'
import MainComponent from '../components/Main'

const IndexPage = () => {
  const [showModalRegulation, setShowModalRegulation] = React.useState(false)

  const [showModalClients, setShowModalClients] = React.useState(false)

  function toggleShowModalRegulation() {
    setShowModalRegulation(!showModalRegulation)
  }

  function toggleShowModalClients() {
    setShowModalClients(!showModalClients)
  }

  return (
    <>
      <Layout>
        <SEO title="Programa de indicação" />
        <MainComponent
          toggleShowModalRegulation={toggleShowModalRegulation}
          toggleShowModalClients={toggleShowModalClients}
        />
      </Layout>
      {showModalRegulation && (
        <ModalRegulation toggleShowModal={toggleShowModalRegulation} />
      )}
      {showModalClients && (
        <ModalClients toggleShowModal={toggleShowModalClients} />
      )}
    </>
  )
}

export default IndexPage

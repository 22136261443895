import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ModalLayout from '../ModalLayout'

import * as S from './styled'

const ModalRegulation = ({ toggleShowModal }) => {
  const { clientsLogos } = useStaticQuery(
    graphql`
      query {
        clientsLogos: allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: { eq: "clients" }
          }
        ) {
          nodes {
            childImageSharp {
              fixed(width: 330) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    `
  )

  return (
    <ModalLayout title="Nossos clientes" toggleShowModal={toggleShowModal}>
      <S.ClientTitle>
        Hospitais e clínicas que utilizam o PEG Contas.
      </S.ClientTitle>

      <S.ClientLogoGrid>
        {clientsLogos.nodes.map((clientLogo, index) => (
          <S.ClientLogoWrapper key={`client-logo-${index}`}>
            <S.ClientLogo fixed={clientLogo.childImageSharp.fixed} />
          </S.ClientLogoWrapper>
        ))}
      </S.ClientLogoGrid>
    </ModalLayout>
  )
}

export default ModalRegulation

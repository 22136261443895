import styled from 'styled-components'
import Img from 'gatsby-image'
import media from '../../styles/media-query'

export const ClientTitle = styled.h2`
  text-align: center;
  margin-bottom: 60px;
  ${media.lessThan('680px')`
    font-size: 16px !important;
    margin-bottom: 30px;
  `}
`

export const ClientLogo = styled(Img)`
  width: 100%;
  max-width: 330px;
  margin: auto;
`

export const ClientLogoWrapper = styled.div`
  width: 33.33333%;
  padding: 30px;
  ${media.lessThan('sm')`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  ${media.lessThan('680px')`
    width: 100%;
  `}
`

export const ClientLogoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-content: center;
`

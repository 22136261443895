import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'

import * as S from './styled'

const DisabledWindowScrollBarStyles = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
  body {
    -webkit-overflow-scrolling: touch;
  }
`

const ModalLayout = ({
  children,
  title,
  toggleShowModal,
  downloadRegulationButton
}) => {
  const {
    closeIcon,
    closeIconMobile,
    downloadIcon,
    regulationModalIcon,
    regulationModalIconMobile,
    clientsModalIcon,
    clientsModalIconMobile
  } = useStaticQuery(
    graphql`
      query {
        closeIcon: file(relativePath: { eq: "icons/close-icon.svg" }) {
          publicURL
        }
        closeIconMobile: file(
          relativePath: { eq: "icons/close-icon-small.svg" }
        ) {
          publicURL
        }
        downloadIcon: file(relativePath: { eq: "icons/download-icon.svg" }) {
          publicURL
        }
        regulationModalIcon: file(
          relativePath: { eq: "icons/regulation-modal-icon.svg" }
        ) {
          publicURL
        }
        regulationModalIconMobile: file(
          relativePath: { eq: "icons/regulamento-icon-small.svg" }
        ) {
          publicURL
        }
        clientsModalIcon: file(
          relativePath: { eq: "icons/clients-modal-icon.svg" }
        ) {
          publicURL
        }
        clientsModalIconMobile: file(
          relativePath: { eq: "icons/clientes-icon-small.svg" }
        ) {
          publicURL
        }
      }
    `
  )

  return (
    <S.ModalLayoutWrapper onClick={toggleShowModal}>
      <DisabledWindowScrollBarStyles />
      <S.ModalLayoutContainer
        hasButton={!!downloadRegulationButton}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <S.ModalLayoutHeader>
          <S.ModalLayoutHeaderTitle>
            {title === 'Regulamento' && (
              <>
                <img src={regulationModalIcon.publicURL} alt="Regulamento" />
                <img
                  src={regulationModalIconMobile.publicURL}
                  alt="Regulamento"
                />
              </>
            )}
            {title === 'Nossos clientes' && (
              <>
                <img src={clientsModalIcon.publicURL} alt="Nossos clientes" />
                <img
                  src={clientsModalIconMobile.publicURL}
                  alt="Nossos clientes"
                />
              </>
            )}
            {title}
          </S.ModalLayoutHeaderTitle>

          <S.ModalLayoutHeaderCloseLink onClick={toggleShowModal}>
            <img src={closeIcon.publicURL} alt="Fechar" />
            <img src={closeIconMobile.publicURL} alt="Fechar" />
          </S.ModalLayoutHeaderCloseLink>
        </S.ModalLayoutHeader>

        <S.ModalLayoutContent hasButton={!!downloadRegulationButton}>
          {children}
        </S.ModalLayoutContent>
        {downloadRegulationButton && (
          <S.DownloadRegulationButtonWrapper>
            <S.DownloadRegulationButton
              href={downloadRegulationButton}
              download
            >
              <img src={downloadIcon.publicURL} alt="Baixar regulamento" />
              <span>Baixar regulamento</span>
            </S.DownloadRegulationButton>
          </S.DownloadRegulationButtonWrapper>
        )}
      </S.ModalLayoutContainer>
    </S.ModalLayoutWrapper>
  )
}

ModalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}

export default ModalLayout

import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { useStaticQuery, graphql } from 'gatsby'

import ArrowDownIcon from '../../images/icons/arrow-down-icon.svg'

import * as S from './styled'

const FormInput = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  className,
  type,
  options,
  mask,
  disabled,
  hasError,
  errorMessages
}) => {
  function handleChange(event) {
    onChange(name, event.target.value)
  }

  function handleChangeSelect(event) {
    event.preventDefault()
    onChange(name, event.target.innerText)
    setFocus(false)
  }

  const [isFocused, setFocus] = React.useState(false)

  function focusIn() {
    setFocus(true)
  }

  function focusOut(event) {
    handleChange(event)
    if (type === 'select') return
    onBlur()
    setFocus(false)
  }

  function useClickOutsideOptionElement(ref) {
    useEffect(() => {
      if (type !== 'select') return
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFocus(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const optionsWrapper = useRef(null)
  useClickOutsideOptionElement(optionsWrapper)

  const { inputErrorIcon } = useStaticQuery(
    graphql`
      query {
        inputErrorIcon: file(
          relativePath: { eq: "icons/input-error-icon.svg" }
        ) {
          publicURL
        }
      }
    `
  )

  return (
    <S.FormInputWrapper
      disabled={disabled}
      className={className}
      ref={optionsWrapper}
    >
      <S.FormInputLabel
        htmlFor={name}
        isFocused={isFocused}
        disabled={disabled}
        hasValue={!!value}
        hasError={hasError}
      >
        {label}
      </S.FormInputLabel>
      {(mask && (
        <S.FormInputElement
          as={InputMask}
          mask={mask}
          id={name}
          name={name}
          value={value}
          type="text"
          data-has-value={!!value}
          data-has-error={!!hasError}
          disabled={disabled}
          onChange={handleChange}
          onFocus={focusIn}
          onBlur={focusOut}
        />
      )) || (
        <S.FormInputElement
          id={name}
          name={name}
          value={value}
          readOnly={type === 'select'}
          isSelect={type === 'select'}
          type={!type || type === 'select' || type === 'email' ? 'text' : type}
          data-has-value={!!value}
          data-has-error={!!hasError}
          disabled={disabled}
          onChange={handleChange}
          onFocus={focusIn}
          onBlur={focusOut}
        />
      )}
      {hasError &&
        errorMessages.map((errorMessage, index) => (
          <S.FormInputErrorMessage key={`error-message-${name}-${index}`}>
            <img src={inputErrorIcon.publicURL} alt="Ícone de erro" />
            <span>{errorMessage}</span>
          </S.FormInputErrorMessage>
        ))}
      {type === 'select' && options && (
        <S.FormInputElementSelectArrow
          src={ArrowDownIcon}
          alt="Alterar seleção"
          invert={isFocused}
        />
      )}
      {type === 'select' && options && isFocused && (
        <S.FormInputElementOptionsWrapper>
          <S.FormInputElementOptions>
            {options.map((option, index) => (
              <S.FormInputElementOption key={`select-option-${name}-${index}`}>
                <S.FormInputElementOptionButton
                  isSelected={value === option}
                  onClick={handleChangeSelect}
                >
                  {option}
                </S.FormInputElementOptionButton>
              </S.FormInputElementOption>
            ))}
          </S.FormInputElementOptions>
        </S.FormInputElementOptionsWrapper>
      )}
    </S.FormInputWrapper>
  )
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  options: PropTypes.array,
  mask: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessages: PropTypes.array
}

export default FormInput

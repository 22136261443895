import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import axios from 'axios'

import RegulamentoIcon from '../../images/icons/regulamento-icon.svg'
import ClientesIcon from '../../images/icons/clientes-icon.svg'
import RegulamentoIconSmall from '../../images/icons/regulamento-icon-small.svg'
import ClientesIconSmall from '../../images/icons/clientes-icon-small.svg'

import * as S from './styled'

const Main = ({ toggleShowModalRegulation, toggleShowModalClients }) => {
  const RECAPTCHA_SITE_KEY = '6Lfl5-EZAAAAAKi0eyKC2iA_mOqqVbSsvo3Agh-S'
  const GTM_ANALYTICS_KEY = 'G-B52W7SSSK3'

  const [form, setFormValue] = React.useState({
    name: '',
    workplace: '',
    email: '',
    whatsapp: '',

    indicatedName: '',
    indicatedWorkplace: '',
    indicatedPosition: '',
    indicatedEmail: '',
    indicatedWhatsapp: ''
  })

  const [showMobileMenu, setShowMobileMenu] = React.useState(false)

  const [currentStep, setCurrentStep] = React.useState(1)

  const [isSubmiting, setIsSubmiting] = React.useState(false)

  const [termsAgreed, setTermsAgreed] = React.useState(false)

  const [hasCheckboxError, setHasCheckboxError] = React.useState(false)

  const positionOptions = [
    'Faturista',
    'Presidente',
    'Diretor',
    'Gerente',
    'Coordenador'
  ]

  const [formFieldsStep1, setFormFieldsStep1] = React.useState([
    {
      label: 'Seu nome',
      name: 'name',
      hasError: false,
      errorMessages: []
    },
    {
      label: 'Hospital/clínica que você trabalha',
      name: 'workplace',
      hasError: false,
      errorMessages: []
    },
    {
      label: 'Seu e-mail',
      name: 'email',
      type: 'email',
      hasError: false,
      errorMessages: []
    },
    {
      label: 'Seu whatsapp',
      name: 'whatsapp',
      mask: '(99) 99999-9999',
      hasError: false,
      errorMessages: []
    }
  ])

  const [formFieldsStep2, setFormFieldsStep2] = React.useState([
    {
      label: 'Nome do indicado',
      name: 'indicatedName',
      hasError: false,
      errorMessages: []
    },
    {
      label: 'Hospital/clínica que o indicado trabalha',
      name: 'indicatedWorkplace',
      hasError: false,
      errorMessages: []
    },
    {
      label: 'Cargo/função do indicado',
      name: 'indicatedPosition',
      type: 'select',
      options: positionOptions,
      hasError: false,
      errorMessages: []
    },
    {
      label: 'E-mail do indicado',
      name: 'indicatedEmail',
      type: 'email',
      hasError: false,
      errorMessages: []
    },
    {
      label: 'Whatsapp do indicado',
      name: 'indicatedWhatsapp',
      mask: '(99) 99999-9999',
      hasError: false,
      errorMessages: []
    }
  ])

  const pointsSteps = [
    {
      title: 'Indique',
      description:
        'Indique pessoas para conhecerem o <strong>PEG Contas</strong>.'
    },
    {
      title: 'Acumule',
      description:
        'Cada <strong>indicado</strong> que participar de uma conversa conosco acumula <strong>PEG Bits</strong>*'
    },
    {
      title: 'Ganhe',
      description:
        'A cada contrato fechado com o PEG Contas, <strong>quem indicou</strong> ganha um vale presente de <strong>R$1.000,00</strong>'
    },
    {
      title: 'Seja o melhor',
      description:
        'Os <strong>2 melhores<strong> indicadores ganharão um curso no valor de até <strong>R$3.000,00</strong>'
    }
  ]

  useEffect(() => {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
    document.body.appendChild(recaptchaScript)

    const gtmScript = document.createElement('script')
    gtmScript.setAttribute('async', true)
    gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${GTM_ANALYTICS_KEY}`

    const gtmScriptInline = document.createElement('script')
    gtmScriptInline.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments)}
      gtag('js', new Date());

      gtag('config', '${GTM_ANALYTICS_KEY}');
    `

    document.head.appendChild(gtmScript)
    document.head.appendChild(gtmScriptInline)
  }, [])

  function toggleTermsAgreed() {
    setTermsAgreed(!termsAgreed)
    if (!termsAgreed) setHasCheckboxError(false)
  }

  const getErrors = (field, form) => {
    if (!form[field.name]) {
      return [`Informe ${field.label.toLowerCase()}`]
    }

    const emailRegex = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?(?:\.\w{2})?$/
    if (field.type === 'email' && !emailRegex.test(form[field.name])) {
      return ['Informe um e-mail correto']
    }

    const phoneRegex = /\(\d{2,}\) \d{4,}-\d{4}/
    if (
      field.mask === '(99) 99999-9999' &&
      !phoneRegex.test(form[field.name])
    ) {
      return ['Informe o whatsapp corretamente']
    }

    return []
  }

  async function handleChange(key, newValue) {
    const newForm = { ...form }
    newForm[key] = newValue
    setFormValue(newForm)

    validadeFieldsStep1(key, newForm, true)
    validadeFieldsStep2(key, newForm, true)
  }

  function validadeFieldsStep1(stepName, form, checkHasError = false) {
    const index = formFieldsStep1.findIndex((step) => step.name === stepName)
    if (index === -1 || (!!checkHasError && !formFieldsStep1[index].hasError))
      return

    const copyFormField = [...formFieldsStep1]
    const errorArray = getErrors(copyFormField[index], form)
    copyFormField[index].hasError = !!errorArray && !!errorArray.length
    copyFormField[index].errorMessages = errorArray
    setFormFieldsStep1(copyFormField)
  }

  function validadeFieldsStep2(stepName, form, checkHasError = false) {
    const index = formFieldsStep2.findIndex((step) => step.name === stepName)
    if (index === -1 || (!!checkHasError && !formFieldsStep2[index].hasError))
      return

    const copyFormField = [...formFieldsStep2]
    const errorArray = getErrors(copyFormField[index], form)
    copyFormField[index].hasError = !!errorArray && !!errorArray.length
    copyFormField[index].errorMessages = errorArray
    setFormFieldsStep2(copyFormField)
  }

  let hasSomeData = Object.keys(form).some((key) => !!form[key])

  function tryGoStep1(event) {
    event.preventDefault()
    setCurrentStep(1)
  }

  function tryGoStep2() {
    if (currentStep !== 1) return

    const copyFormField = formFieldsStep1.map((field) => {
      const errorArray = getErrors(field, form)
      return {
        ...field,
        hasError: !!errorArray && !!errorArray.length,
        errorMessages: errorArray
      }
    })
    setFormFieldsStep1(copyFormField)

    const hasError = copyFormField.some(({ hasError }) => !!hasError)
    if (hasError) return

    setCurrentStep(2)
  }

  function submitForm(event) {
    event.preventDefault()
    if (currentStep === 1) {
      tryGoStep2()
      return
    }

    const copyFormField = formFieldsStep2.map((field) => {
      const errorArray = getErrors(field, form)
      return {
        ...field,
        hasError: !!errorArray && !!errorArray.length,
        errorMessages: errorArray
      }
    })
    setFormFieldsStep2(copyFormField)

    const hasError = copyFormField.some(({ hasError }) => !!hasError)
    if (hasError) return

    setHasCheckboxError(!termsAgreed)
    if (!termsAgreed) return

    setIsSubmiting(true)

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
        .then((recaptcha_token) => {
          const formattedForm = {
            ...form,
            whatsapp: `+55 ${form.whatsapp.replace('(', '').replace(')', '')}`,
            indicatedWhatsapp: `+55 ${form.indicatedWhatsapp
              .replace('(', '')
              .replace(')', '')}`,
            recaptcha_token
          }

          axios
            .post(
              'https://api.umctecnologia.com/peg/mgm/indication',
              formattedForm,
              { headers: { 'Access-Control-Allow-Origin': '*' } }
            )
            .then(() => {
              setCurrentStep(3)
            })
            .catch(() => {
              setCurrentStep(4)
            })
            .finally(() => {
              setIsSubmiting(false)
            })
        })
    })
  }

  function doAnotherIndication(event) {
    event.preventDefault()
    setFormValue({
      ...form,
      indicatedName: '',
      indicatedWorkplace: '',
      indicatedPosition: '',
      indicatedEmail: '',
      indicatedWhatsapp: ''
    })
    setCurrentStep(2)
  }

  function tryAgain() {
    window.location.reload()
  }

  const {
    pegLogo,
    backgroundPhoto,
    menuIcon,
    menuCloseIcon,
    inputErrorIcon,
    backgroundDecoration
  } = useStaticQuery(
    graphql`
      query {
        pegLogo: file(relativePath: { eq: "peg-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 330) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        backgroundPhoto: file(
          relativePath: { eq: "mobile-background-photo.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        menuIcon: file(relativePath: { eq: "icons/menu-icon.svg" }) {
          publicURL
        }
        menuCloseIcon: file(relativePath: { eq: "icons/menu-close-icon.svg" }) {
          publicURL
        }
        inputErrorIcon: file(
          relativePath: { eq: "icons/input-error-icon.svg" }
        ) {
          publicURL
        }
        backgroundDecoration: file(
          relativePath: { eq: "background-decoration.svg" }
        ) {
          publicURL
        }
      }
    `
  )

  return (
    <S.MainWrapper>
      <S.MainFormContainer>
        <S.MainFormContainerBackgroundDecorationWrapper>
          <img src={backgroundDecoration.publicURL} alt="Imagem de fundo" />
        </S.MainFormContainerBackgroundDecorationWrapper>
        <S.MainLogoWrapper>
          <S.MainLogo fluid={pegLogo.childImageSharp.fluid} />
        </S.MainLogoWrapper>
        <S.MainFormWrapperDecorationSmallWrapper>
          <S.MainFormWrapperDecorationSmall
            fluid={backgroundPhoto.childImageSharp.fluid}
          />
        </S.MainFormWrapperDecorationSmallWrapper>
        <S.MainFormWrapper>
          <S.MainFormDescription>
            <S.MainFormDescriptionTitle>
              Programa de Indicação PEG Bit
            </S.MainFormDescriptionTitle>
            <S.MainFormDescriptionText>
              Convide parceiros a conhecerem o PEG Contas, acumule PEG Bits,
              vale presentes e <strong>vários outros benefícios!!</strong>
            </S.MainFormDescriptionText>
          </S.MainFormDescription>

          <S.MainForm onSubmit={submitForm}>
            {currentStep !== 4 && (
              <S.MainFormStepsController currentStep={currentStep}>
                {['Seus dados', 'Dados do indicado', 'Sucesso'].map(
                  (option, index) => (
                    <S.MainFormStepsControllerOption
                      type="button"
                      key={`step-${index + 1}`}
                      data-step-number={index + 1}
                      isActive={
                        (currentStep === 1 &&
                          hasSomeData &&
                          currentStep === index + 1) ||
                        (currentStep > 1 && currentStep >= index + 1)
                      }
                    >
                      <S.MainFormStepsControllerOptionDescription
                        isActive={
                          (currentStep === 1 &&
                            hasSomeData &&
                            currentStep === index + 1) ||
                          (currentStep > 1 && currentStep >= index + 1)
                        }
                      >
                        {option}
                      </S.MainFormStepsControllerOptionDescription>
                    </S.MainFormStepsControllerOption>
                  )
                )}
              </S.MainFormStepsController>
            )}
            <S.MainFormStep isCurrentStep={currentStep === 1}>
              <S.MainFormStepTitle>Preencha seus dados</S.MainFormStepTitle>
              {formFieldsStep1.map(
                ({
                  label,
                  name,
                  type,
                  options,
                  mask,
                  hasError,
                  errorMessages
                }) => (
                  <S.MainFormInput
                    key={name}
                    label={label}
                    value={form[name]}
                    name={name}
                    type={type}
                    options={options}
                    mask={mask}
                    disabled={isSubmiting}
                    hasError={hasError}
                    error={hasError}
                    errorMessages={errorMessages}
                    onChange={handleChange}
                    onBlur={() => {
                      validadeFieldsStep1(name, form)
                    }}
                  />
                )
              )}

              <S.MainFormSubmitButtonWrapper>
                <S.MainFormSubmitButton>Continuar</S.MainFormSubmitButton>
              </S.MainFormSubmitButtonWrapper>
            </S.MainFormStep>

            <S.MainFormStep isCurrentStep={currentStep === 2}>
              <S.MainFormStepTitle>
                Preencha os dados do indicado
              </S.MainFormStepTitle>
              {formFieldsStep2.map(
                ({
                  label,
                  name,
                  type,
                  options,
                  mask,
                  hasError,
                  errorMessages
                }) => (
                  <S.MainFormInput
                    key={name}
                    label={label}
                    value={form[name]}
                    name={name}
                    type={type}
                    options={options}
                    mask={mask}
                    disabled={isSubmiting}
                    hasError={hasError}
                    errorMessages={errorMessages}
                    onChange={handleChange}
                    onBlur={() => {
                      validadeFieldsStep2(name, form)
                    }}
                  />
                )
              )}

              <S.MainFormAgreement>
                <S.MainFormAgreementCheckbox
                  type="checkbox"
                  id="agreement"
                  value={termsAgreed}
                  disabled={isSubmiting}
                  onChange={toggleTermsAgreed}
                  hasError={hasCheckboxError}
                />
                <S.MainFormAgreementLabel>
                  Concordo com o{' '}
                  <button type="button" onClick={toggleShowModalRegulation}>
                    Regulamento do programa
                  </button>
                  .
                </S.MainFormAgreementLabel>

                <div
                  className="g-recaptcha"
                  data-sitekey={RECAPTCHA_SITE_KEY}
                  data-size="invisible"
                ></div>

                <S.MainFormAgreementError
                  style={{ display: hasCheckboxError ? '' : 'none' }}
                >
                  <img src={inputErrorIcon.publicURL} alt="Ícone de erro" />
                  <span>
                    É necessário concordar com o regulamento do programa
                  </span>
                </S.MainFormAgreementError>
              </S.MainFormAgreement>

              <S.MainFormSubmitButtonWrapper>
                <S.MainFormSubmitButton
                  disabled={isSubmiting}
                  isLoading={isSubmiting}
                  onClick={tryGoStep1}
                  secondary
                >
                  Voltar
                </S.MainFormSubmitButton>
                <S.MainFormSubmitButton
                  disabled={isSubmiting}
                  isLoading={isSubmiting}
                >
                  Enviar
                </S.MainFormSubmitButton>
              </S.MainFormSubmitButtonWrapper>
            </S.MainFormStep>

            <S.MainFormStep isCurrentStep={currentStep === 4}>
              <S.MainFormCompleteWrapper>
                <S.MainFormCompleteTitle>
                  Opa! Algo deu errado :(
                </S.MainFormCompleteTitle>
                <S.MainFormCompleteText>
                  Sentimos muito pelo ocorrido.
                </S.MainFormCompleteText>

                <S.MainFormSubmitButtonWrapper>
                  <S.MainFormSubmitButton type="button" onClick={tryAgain}>
                    Tente novamente
                  </S.MainFormSubmitButton>
                </S.MainFormSubmitButtonWrapper>
              </S.MainFormCompleteWrapper>
            </S.MainFormStep>

            <S.MainFormStep isCurrentStep={currentStep === 3}>
              <S.MainFormCompleteWrapper>
                <S.MainFormCompleteTitle>
                  Pronto! Agora é só esperar.
                </S.MainFormCompleteTitle>
                <S.MainFormCompleteText>
                  Aproveite!
                  <br />
                  <br />
                  Continue convidando colegas pelo programa PEG Bit e aumente
                  suas chances!!
                </S.MainFormCompleteText>

                <S.MainFormSubmitButtonWrapper>
                  <S.MainFormSubmitButton
                    type="button"
                    onClick={doAnotherIndication}
                  >
                    Convidar mais pessoas
                  </S.MainFormSubmitButton>
                </S.MainFormSubmitButtonWrapper>
              </S.MainFormCompleteWrapper>
            </S.MainFormStep>
          </S.MainForm>
        </S.MainFormWrapper>
      </S.MainFormContainer>
      <S.MainDescriptionContainer>
        <S.MainDescriptionHeader>
          <S.MainDescriptionHeading>É muito simples!</S.MainDescriptionHeading>

          <S.MainDescriptionLinksWrapper>
            <S.MainDescriptionLinksLogoWrapper>
              <S.MainDescriptionLinksLogo
                fluid={pegLogo.childImageSharp.fluid}
              />
              <S.MainDescriptionLinksToggler
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                <img
                  src={
                    showMobileMenu
                      ? menuCloseIcon.publicURL
                      : menuIcon.publicURL
                  }
                  alt="Alterar navegação"
                />
              </S.MainDescriptionLinksToggler>
            </S.MainDescriptionLinksLogoWrapper>
            <S.MainDescriptionLinks show={showMobileMenu}>
              <S.MainDescriptionLink onClick={toggleShowModalRegulation}>
                <S.MainDescriptionLinkIcon
                  src={RegulamentoIcon}
                  alt="Regulamento"
                />
                <S.MainDescriptionLinkIconSmall
                  src={RegulamentoIconSmall}
                  alt="Regulamento"
                />
                Regulamento
              </S.MainDescriptionLink>
              <S.MainDescriptionLink onClick={toggleShowModalClients}>
                <S.MainDescriptionLinkIcon
                  src={ClientesIcon}
                  alt="Nossos clientes"
                />
                <S.MainDescriptionLinkIconSmall
                  src={ClientesIconSmall}
                  alt="Nossos clientes"
                />
                Nossos clientes
              </S.MainDescriptionLink>
            </S.MainDescriptionLinks>
          </S.MainDescriptionLinksWrapper>
        </S.MainDescriptionHeader>

        <S.MainDescriptionHeadingSmall>
          Veja como é simples participar.
        </S.MainDescriptionHeadingSmall>
        <S.MainDescriptionSteps id="description-steps">
          {pointsSteps.map(({ title, description: __html }, index) => (
            <S.MainDescriptionStep
              key={`step-${index + 1}`}
              stepNumber={index + 1}
            >
              <S.MainDescriptionStepNumber stepNumber={index + 1} />
              <S.MainDescriptionStepText>
                <S.MainDescriptionStepTitle>{title}</S.MainDescriptionStepTitle>
                <S.MainDescriptionStepDescription
                  dangerouslySetInnerHTML={{ __html }}
                />
              </S.MainDescriptionStepText>
            </S.MainDescriptionStep>
          ))}
        </S.MainDescriptionSteps>
      </S.MainDescriptionContainer>
      <S.MainMobileMenuFilter
        show={showMobileMenu}
        onClick={() => setShowMobileMenu(false)}
      />
    </S.MainWrapper>
  )
}

export default Main

import styled from 'styled-components'
import Img from 'gatsby-image'
import media from '../../styles/media-query'

export const LayoutWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

export const LayoutBackgroundDecorationsWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 1980px;
  > * {
    position: absolute;
    &:nth-child(1) {
      // greenCircleDecoration
      bottom: 100px;
      left: 10px;
      svg {
        width: 40vw;
        height: auto;
      }
    }
    &:nth-child(2) {
      // whiteElipseDecoration
      left: 72px;
      bottom: 40vw;
      svg {
        width: 78px;
        height: auto;
        transform: translate3d(0, 50%, 0);
      }
    }
    &:nth-child(3) {
      // yellowElipseDecoration
      bottom: 15vw;
      right: 40%;
      svg {
        width: 78px;
        height: auto;
      }
      ${media.lessThan('xl')`
        bottom: 10vw;
      `}
      ${media.lessThan('md')`
        display: none;
      `}
    }
    &:nth-child(4) {
      // yellowCircleDecoration
      bottom: 0;
      right: 0;
      svg {
        width: 30vw;
        height: auto;
      }
    }
  }
  ${media.lessThan('640px')`
    display: none;
  `}
`

export const LayoutBackgroundPhotoWrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: 800px;
  bottom: -124px;
  right: 0;
  user-select: none;

  ${media.lessThan('md')`
    max-width: 600px;
  `}
  ${media.lessThan('sm')`
    display: none;
  `}
`

export const LayoutBackgroundPhoto = styled(Img)``

export const LayoutContent = styled.section`
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  width: 100%;
  max-width: 1280px;

  ${media.lessThan('lg')`
    max-width: 1126px;
  `}

  ${media.lessThan('md')`
    max-width: 906px;
  `}

  ${media.lessThan('sm')`
    max-width: 600px;
    padding: 0 20px;
  `}
`

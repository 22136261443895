import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'
import GlobalStyles from '../../styles/global'
import ErrorBrowser from '../ErrorBrowser'

const Layout = ({ children }) => {
  const { backgroundPhoto } = useStaticQuery(
    graphql`
      query {
        backgroundPhoto: file(
          relativePath: { eq: "mobile-background-photo.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  const [detectIE, setDetectIE] = React.useState(false)
  function useDetectIE() {
    useEffect(() => {
      const detectIE = /*@cc_on!@*/ false || !!document.documentMode

      setDetectIE(detectIE)
    })
  }

  useDetectIE()

  function useRepositionBackground(ref) {
    useEffect(() => {
      function repositionBackground() {
        if (detectIE) return
        const stepsElement = document.getElementById('description-steps')

        const minBackgroundPosition =
          stepsElement.clientHeight + stepsElement.offsetTop
        if (minBackgroundPosition > ref.current.offsetTop) {
          ref.current.style.transform = `translate3d(0, ${
            minBackgroundPosition - ref.current.offsetTop
          }px, 0)`
        } else {
          ref.current.style.transform = ''
        }
      }

      repositionBackground()
      window.addEventListener('resize', repositionBackground)
      return () => {
        window.removeEventListener('resize', repositionBackground)
      }
    }, [ref])
  }

  const backgroundWrapper = useRef(null)
  useRepositionBackground(backgroundWrapper)

  return (
    <>
      <GlobalStyles />
      {detectIE && <ErrorBrowser />}
      {!detectIE && (
        <S.LayoutWrapper>
          <S.LayoutBackgroundPhotoWrapper ref={backgroundWrapper}>
            <S.LayoutBackgroundPhoto
              fluid={backgroundPhoto.childImageSharp.fluid}
            />
          </S.LayoutBackgroundPhotoWrapper>
          <S.LayoutContent>{children}</S.LayoutContent>
        </S.LayoutWrapper>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
